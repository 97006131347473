import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1c7ccf62 = () => interopDefault(import('../client/pages/kits/index.vue' /* webpackChunkName: "pages/kits/index" */))
const _48c096aa = () => interopDefault(import('../client/pages/lojas/index.vue' /* webpackChunkName: "pages/lojas/index" */))
const _1fba3406 = () => interopDefault(import('../client/pages/mapa-do-site/index.vue' /* webpackChunkName: "pages/mapa-do-site/index" */))
const _75891d44 = () => interopDefault(import('../client/pages/minha-conta/index.vue' /* webpackChunkName: "pages/minha-conta/index" */))
const _5f714d27 = () => interopDefault(import('../client/pages/motos/index.vue' /* webpackChunkName: "pages/motos/index" */))
const _b0fc9280 = () => interopDefault(import('../client/pages/reset/index.vue' /* webpackChunkName: "pages/reset/index" */))
const _073a4b89 = () => interopDefault(import('../client/pages/veiculos/index.vue' /* webpackChunkName: "pages/veiculos/index" */))
const _4428bf24 = () => interopDefault(import('../client/pages/checkout/carrinho/index.vue' /* webpackChunkName: "pages/checkout/carrinho/index" */))
const _712bf1cc = () => interopDefault(import('../client/pages/checkout/confirmacao/index.vue' /* webpackChunkName: "pages/checkout/confirmacao/index" */))
const _2d643722 = () => interopDefault(import('../client/pages/checkout/pagamento/index.vue' /* webpackChunkName: "pages/checkout/pagamento/index" */))
const _771b7259 = () => interopDefault(import('../client/pages/minha-conta/enderecos/index.vue' /* webpackChunkName: "pages/minha-conta/enderecos/index" */))
const _ad80580a = () => interopDefault(import('../client/pages/minha-conta/meus-dados/index.vue' /* webpackChunkName: "pages/minha-conta/meus-dados/index" */))
const _33812b46 = () => interopDefault(import('../client/pages/minha-conta/pedidos/index.vue' /* webpackChunkName: "pages/minha-conta/pedidos/index" */))
const _550581bf = () => interopDefault(import('../client/pages/usuario/cadastro/index.vue' /* webpackChunkName: "pages/usuario/cadastro/index" */))
const _3b1b650e = () => interopDefault(import('../client/pages/usuario/login/index.vue' /* webpackChunkName: "pages/usuario/login/index" */))
const _627086ce = () => interopDefault(import('../client/pages/checkout/confirmacao/pix.vue' /* webpackChunkName: "pages/checkout/confirmacao/pix" */))
const _50e8bae2 = () => interopDefault(import('../client/pages/produto/_id/_.vue' /* webpackChunkName: "pages/produto/_id/_" */))
const _4ccd557f = () => interopDefault(import('../client/pages/kit/_id/_.vue' /* webpackChunkName: "pages/kit/_id/_" */))
const _52de6a01 = () => interopDefault(import('../client/pages/lista/_.vue' /* webpackChunkName: "pages/lista/_" */))
const _55d27a44 = () => interopDefault(import('../client/pages/informacoes-gerais/_.vue' /* webpackChunkName: "pages/informacoes-gerais/_" */))
const _34921800 = () => interopDefault(import('../client/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/kits",
    component: _1c7ccf62,
    name: "kits"
  }, {
    path: "/lojas",
    component: _48c096aa,
    name: "lojas"
  }, {
    path: "/mapa-do-site",
    component: _1fba3406,
    name: "mapa-do-site"
  }, {
    path: "/minha-conta",
    component: _75891d44,
    name: "minha-conta"
  }, {
    path: "/motos",
    component: _5f714d27,
    name: "motos"
  }, {
    path: "/reset",
    component: _b0fc9280,
    name: "reset"
  }, {
    path: "/veiculos",
    component: _073a4b89,
    name: "veiculos"
  }, {
    path: "/checkout/carrinho",
    component: _4428bf24,
    name: "checkout-carrinho"
  }, {
    path: "/checkout/confirmacao",
    component: _712bf1cc,
    name: "checkout-confirmacao"
  }, {
    path: "/checkout/pagamento",
    component: _2d643722,
    name: "checkout-pagamento"
  }, {
    path: "/minha-conta/enderecos",
    component: _771b7259,
    name: "minha-conta-enderecos"
  }, {
    path: "/minha-conta/meus-dados",
    component: _ad80580a,
    name: "minha-conta-meus-dados"
  }, {
    path: "/minha-conta/pedidos",
    component: _33812b46,
    name: "minha-conta-pedidos"
  }, {
    path: "/usuario/cadastro",
    component: _550581bf,
    name: "usuario-cadastro"
  }, {
    path: "/usuario/login",
    component: _3b1b650e,
    name: "usuario-login"
  }, {
    path: "/checkout/confirmacao/pix",
    component: _627086ce,
    name: "checkout-confirmacao-pix"
  }, {
    path: "/produto/:id?/*",
    component: _50e8bae2,
    name: "produto-id-all"
  }, {
    path: "/kit/:id?/*",
    component: _4ccd557f,
    name: "kit-id-all"
  }, {
    path: "/lista/*",
    component: _52de6a01,
    name: "lista-all"
  }, {
    path: "/informacoes-gerais/*",
    component: _55d27a44,
    name: "informacoes-gerais-all"
  }, {
    path: "/",
    component: _34921800,
    name: "index"
  }],

  parseQuery: function(query) {
			const qs = require("qs")

			return qs.parse(query)
		},
  stringifyQuery: function(query) {
			const qs = require("qs")

			const result = qs.stringify(query)

			return result ? `?${result}` : ""
		},
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
